<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">Laporan Perjalanan Dinas Pegawai</h4>
            </div>
          </div>
          <div class="card-body">
            <b-card>
              <div class="form-row">
                <b-col md="6" class="mb-3">
                  <label for="no_spt">Nama Pelaksana</label>
                  <v-select :options="opt.pegawai" :reduce="code => code.code" label="label" v-model="filter.pegawai_id" class="style-chooser">
                  </v-select>
                </b-col>
              </div>
              <div class="form-row">
                <b-col md="3" class="mb-3">
                  <label for="colFormLabel">Tgl Berangkat</label>
                  <flat-pickr class="form-control" :config="dateConfig" @on-change="tglBrgktOnChange" v-model="filter.tgl_berangkat"></flat-pickr>
                </b-col>
                <b-col md="3" class="mb-3">
                  <label for="colFormLabel">Tgl Kembali</label>
                  <flat-pickr class="form-control" :config="brgktConfig" v-model="filter.tgl_kembali"></flat-pickr>
                </b-col>
              </div>
              <div class="form-row">
                <b-col md="6" class="mb-3">
                  <label for="colFormLabel" >Status</label>
                  <select class="form-control mb-3" v-model="filter.status">
                    <option selected="ALL">Semua</option>
                    <option value="DRAFT">Draf</option>
                    <option value="INPROGRESS">Proses</option>
                    <option value="FINISH">Selesai</option>
                  </select>
                </b-col>
              </div>
              <button class="btn btn-primary" @click="handleSearch">Cari</button>
            </b-card>
            <vue-good-table v-if="rows.length > 0"
              :columns="columns" 
              :rows="rows"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button size="sm" @click="viewHandle(props)" variant="primary" class="mr-2" title="Edit">
                    <i class="dripicons dripicons-view-list"></i>
                  </b-button >
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';
  import { getDataService, postDataService } from '../../store/modules/crudservices'
  import config from '../../config/config'
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';

  export default {
    name:'rekap.Pegawai',
    data () {
      return {
        dateConfig: {
          wrap: true, 
          altFormat: 'j F Y',
          altInput: true,
          dateFormat: 'Y-m-d'
        },
        brgktConfig: {
          wrap: true, 
          altFormat: 'j F Y',
          altInput: true,
          dateFormat: 'Y-m-d'
        },
        filter: {},
        opt: {
          pegawai: [],
        },
        columns:[ {
            label: 'Nama',
            field: 'full_name'
          }, {
            label: 'No. SPT',
            field: 'no_spt'
          }, {
            label: 'Asal',
            field: 'asal'
          }, {
            label: 'Tujuan',
            field: 'tujuan'
          }, {
            label: 'Tgl. Berangkat',
            field: 'tgl_berangkat'
          }, {
            label: 'Tgl. Kembali',
            field: 'tgl_kembali'
          }, {
            label: 'Jumlah Dibayarkan',
            field: 'jml_biaya'
          }, {
            label: 'Status',
            field: 'status'
          }, {
            label: '',
            field: 'action',
            sortable: false,
            width: '125px',
            tdClass: 'text-center',
          }
        ],
        rows: [],
        form:{
          name: null
        }
      }
    },
    methods: {
      getUser() {
        const url = '/pegawai-search?filter=report'
        getDataService(url).then(response => {
          this.opt.pegawai = response.data
        })
      },
      tglBrgktOnChange(props) {
        this.$set(this.brgktConfig, 'minDate', props[0])
      },
      handleSearch() {
        const filter = this.filter
        const url = '/report/spt/pegawai'
        postDataService(url, filter).then(response => {
          this.rows = response.data.data
        })
      },
      exportHandle(){
        let url = `${config.api_endpoint}/report/spt/selesai/export`
        window.open(url, '_blank')
      },
      viewHandle(props) {
        let url = `/spt/${props.row.id}/sppd`
        this.$router.push(url) 
      }
    },
    components: {
      VueGoodTable, flatPickr
    },
    mounted() {
      this.getUser()
    }
  }
</script>